<template>
    <div>
        <v-card-title v-if="!searchFormDisabled" class="pa-0 mb-4">Select a guest</v-card-title>
        <v-row>
            <v-col cols="12" sm="5" class="pb-0">
                <v-text-field
                    outlined
                    v-model="searchForm.email"
                    :error-messages="firstError('searchForm.email')"
                    label="Email"
                    type="email"
                    :disabled="searchFormDisabled"
                    @keyup.enter="search"
                ></v-text-field>
            </v-col>
            <v-col cols="12" sm="5" class="py-0 pt-sm-3">
                <vue-tel-input-vuetify
                    outlined
                    :preferred-countries="['rs', 'ba', 'me', 'hr', 'si', 'de', 'mk', 'ch']"
                    :valid-characters-only="true"
                    :error-messages="firstError('searchForm.phone')"
                    @input="onPhoneInput"
                    :hint="phone.number.international"
                    persistent-hint
                    :label="$t('Phone')"
                    autocomplete="off"
                    v-model="phoneInput"
                    :disabled="searchFormDisabled"
                />
                <div v-if="makeReservationFromInquiry && makeReservationFromInquiry.id && makeReservationFromInquiry.phone" class="red--text">
                  Copy/Paste in field below: "{{ makeReservationFromInquiry.phone }}"
                </div>
            </v-col>
            <v-col cols="2" class="py-0 py-sm-3">
              <v-btn
                v-if="!searchFormDisabled && (searchForm.email || searchForm.phone)"
                @click.stop="search"
                :disabled="searchFormDisabled"
                class="mb-6 mb-sm-0"
                height="56"
              >
                Search
              </v-btn>
              <v-btn
                v-if="searchFormDisabled"
                @click.stop="resetSearch"
                class="mb-10 mb-sm-0"
                height="56"
              >
                Reset
              </v-btn>
            </v-col>
        </v-row>

        <template v-if="searchResults">
            <template v-if="!searchResultsHasUserOrGuestbook">
                <!-- NO SEARCH RESULTS -->
                <v-row v-if="!searchFormDisabled" class="mt-8 mt-sm-0">
                    <v-col>
                     <v-alert
                        outlined
                        class="text-body-1"
                        >
                        <div v-html="noSearchResultHtml"></div>
                        <div v-if="!searchForm.email && searchForm.skip_email === false">
                            <a @click.stop="skipEmail" class="text-decoration-underline">Or Create new guest without email</a>.
                        </div>
                    </v-alert>
                  </v-col>
                </v-row>
                <v-row
                  v-if="searchFormDisabled && phone.valid && (searchForm.email || searchForm.skip_email)"
                  class="mt-6"
                >
                    <v-col cols="12" sm="5" class="py-0">
                        <v-text-field
                            outlined
                            dense
                            v-model="newGuestForm.first_name"
                            :error-messages="firstError('newGuestForm.first_name')"
                            :label="$t('First Name')"
                            :disabled="newGuestForm.emited"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="5" class="py-0">
                        <v-text-field
                            outlined
                            dense
                            v-model="newGuestForm.last_name"
                            :error-messages="firstError('newGuestForm.last_name')"
                            :label="$t('Last Name')"
                            :disabled="newGuestForm.emited"
                        ></v-text-field>
                    </v-col>
                    <v-col class="py-0 mb-10 mb-md-0">
                        <v-btn
                          v-if="!newGuestForm.emited"
                          @click.stop="createNewGuest"
                          height="40"
                        >
                          Create
                        </v-btn>
                        <div v-else>New guest created</div>
                    </v-col>
                </v-row>
            </template>
            <v-row v-else>
                <!-- HAS SEARCH RESULTS -->
                <v-col>
                    <!-- USERS -->
                    <template v-if="searchResults.users && searchResults.users.length">
                        <template v-for="(user, index) in searchResults.users">
                            <user-guestbook-card
                            v-if="!form.guest_id || (form.guest_id && form.guest_type && form.guest_id === user.id && form.guest_type === RESERVATION_GUEST_TYPE_USER)"
                            :key="index"
                            :user="user"
                            :type="RESERVATION_GUEST_TYPE_USER"
                            :allowSelectUser="userAllowSelectUser"
                            @select="selectUser"
                            />
                        </template>
                    </template>
                    <!-- GUESTBOOK -->
                    <template v-if="searchResults.guestbooks && searchResults.guestbooks.length">
                        <template v-for="(user, index) in searchResults.guestbooks">
                            <user-guestbook-card
                            :key="`guestbook_${index}`"
                            v-if="!form.guest_id || (form.guest_id && form.guest_type && form.guest_id === user.id && form.guest_type === RESERVATION_GUEST_TYPE_GUESTBOOK)"
                            :user="user"
                            :type="RESERVATION_GUEST_TYPE_GUESTBOOK"
                            :allowSelectUser="guestbookAllowSelectUser"
                            @select="selectGuestbook"
                            />
                        </template>
                    </template>
                </v-col>
            </v-row>
        </template>

    </div>
</template>
<script>
import { validationMixin } from 'vuelidate';
import { required, minLength, email } from 'vuelidate/lib/validators';
import UserGuestbookCard from '@/components/reservations/UserGuestbookCard.vue';
import axios from 'axios';
import FormValidationMixin from '@/lib/FormValidationMixin';
import {
  RESERVATION_GUEST_TYPE_USER,
  RESERVATION_GUEST_TYPE_GUESTBOOK,
} from '@/components/calendar/config';

export default {
  mixins: [validationMixin, FormValidationMixin],
  props: {
    makeReservationFromInquiry: {
      type: Object,
      required: false,
    },
  },
  components: {
    UserGuestbookCard,
  },
  data() {
    return {
      phoneInput: null,
      phone: {
        number: {},
      },
      searchForm: {
        email: '',
        phone: '',
        skip_email: false,
      },
      searchResults: undefined,
      searchFormDisabled: false,
      noSearchResultHtml: undefined,

      form: {
        guest_id: undefined,
        guest_type: undefined,
      },
      newGuestForm: {
        first_name: '',
        last_name: '',
        emited: false,
      },

      RESERVATION_GUEST_TYPE_USER,
      RESERVATION_GUEST_TYPE_GUESTBOOK,
    };
  },
  validations: {
    searchForm: {
      email: {
        email,
      },
      phone: {
        validPhoneNumber() {
          if (!this.searchForm.phone) {
            return true;
          }
          return this.phone.valid === true;
        },
      },
    },
    form: {
      guest_id: {
        required,
      },
      guest_type: {
        required,
        txtMinLen: minLength(2),
      },
    },
    newGuestForm: {
      first_name: {
        required,
        txtMinLen: minLength(2),
      },
      last_name: {
        required,
        txtMinLen: minLength(2),
      },
    },
  },
  mounted() {
    if (this.makeReservationFromInquiry && this.makeReservationFromInquiry.id) {
      const inquiry = this.makeReservationFromInquiry;
      this.$store.commit('setPriceCalculator', inquiry.calculator);

      // If inquiry has user_id (ako je ulogovan korisnik)
      if (inquiry.user_id) {
        this.searchForm.email = inquiry.user.email;
        this.$v.searchForm.$touch();
        this.search();
        this.selectUser(inquiry.user_id);
        this.searchForm.email = undefined;
      } else {
        // If inquiry is sent without user_id (kao ne ulogovan korisnik)
        this.searchForm.email = inquiry.email;
        // TODOMARKO dodati da matchuje i po broju telefona
      }
    }
  },
  computed: {
    searchResultsHasUserOrGuestbook() {
      return this.searchResults && ((this.searchResults.users && this.searchResults.users.length > 0) || (this.searchResults.guestbooks && this.searchResults.guestbooks.length > 0));
    },
    userAllowSelectUser() {
      if (this.form.guest_id) {
        return false;
      }
      if (this.searchResults.users && this.searchResults.users.length > 1) {
        return true;
      }
      return false;
    },
    guestbookAllowSelectUser() {
      if (this.form.guest_id) {
        return false;
      }
      if (this.searchResults.users && this.searchResults.users.length > 0) {
        return false;
      }
      if (this.searchResults.guestbooks && this.searchResults.guestbooks.length > 1) {
        return true;
      }
      return false;
    },
  },
  methods: {
    onPhoneInput(formattedNumber, phone) {
      this.phone = phone;
      this.searchForm.phone = phone.number.e164;
    },
    resetSearch() {
      this.form = {
        guest_id: undefined,
        guest_type: undefined,
      };
      this.searchFormDisabled = false;
      this.searchResults = undefined;
      this.noSearchResultHtml = undefined;
      this.searchForm.skip_email = false;
      this.newGuestForm.first_name = undefined;
      this.newGuestForm.last_name = undefined;
      this.newGuestForm.emited = false;
      this.$v.newGuestForm.$reset();
      this.$emit('reset');
    },
    search() {
      this.$v.searchForm.$touch();
      if (this.$v.searchForm.$invalid) {
        return;
      }
      this.$store.dispatch('loading', true);
      axios
        .post('/guestbook/search', {
          ...this.searchForm,
        })
        .then(({ data }) => {
          this.searchFormDisabled = true;
          this.searchResults = data;

          if (this.searchResults.users.length > 1) {
            // console.log('CASE 1');
            // it has multiple users, admin need to choose.
          } else if (this.searchResults.users.length === 1) {
            // console.log('CASE 2');
            this.form.guest_type = RESERVATION_GUEST_TYPE_USER;
            this.form.guest_id = this.searchResults.users[0].id;
            this.$emit('selectUser', this.searchResults.users[0].id);
            console.log('before emit', this.searchResults.users[0]);
          } else if (this.searchResults.guestbooks.length > 1) {
            // console.log('CASE 3');
            // it has multiple guestbooks, admin need to choose.
          } else if (this.searchResults.guestbooks.length === 1 && this.searchResults.users.length === 0) {
            // console.log('CASE 4');
            this.$emit('selectGuestbook', this.searchResults.guestbooks[0].id);
            this.form.guest_type = RESERVATION_GUEST_TYPE_GUESTBOOK;
            this.form.guest_id = this.searchResults.guestbooks[0].id;
          } else {
            // console.log('CASE 5');
            if (!this.phone.valid || !this.searchForm.email) {
              this.searchFormDisabled = false;
            }
            this.noSearchResultHtml = this.noSearchResultText();
          }

          this.$store.dispatch('loading', false);
        })
        .catch((error) => {
          this.$store.dispatch('setErrors', error.response.data.errors);
          this.$store.dispatch('loading', false);
        });
    },
    noSearchResultText() {
      if (!this.phone.valid) {
        return `Search:
        ${(this.searchForm.email ? `<br/>- <b>email: ${this.searchForm.email}</b><br/>` : '')}
         do not match any results, please type <b>phone number</b> and search again.
        `;
      }
      if (!this.searchForm.email) {
        return `Search:
        ${(this.phone.valid ? `<br/>- <b>phone: ${this.searchForm.phone}</b><br/>` : ' ')}
         do not match any results, please type <b>email</b> and search again.
        `;
      }
      return `Search:
        ${(this.searchForm.email ? `<br/>- <b>email: ${this.searchForm.email}</b><br/>` : '')}
        ${(this.phone.valid ? `- <b>phone: ${this.searchForm.phone}</b><br/>` : '')}
        do not match any results.<br/><br/> please fill the form below and <b>Create new guest</b>
      `;
    },
    selectUser(id) {
      this.form.guest_id = id;
      this.form.guest_type = RESERVATION_GUEST_TYPE_USER;
      this.$emit('selectUser', id);
    },
    selectGuestbook(id) {
      this.form.guest_id = id;
      this.form.guest_type = RESERVATION_GUEST_TYPE_GUESTBOOK;
      this.$emit('selectGuestbook', id);
    },
    skipEmail() {
      this.searchForm.skip_email = true;
      this.searchFormDisabled = true;
    },
    createNewGuest() {
      this.$v.newGuestForm.$touch();
      if (this.$v.newGuestForm.$invalid) {
        return;
      }
      this.$store.dispatch('loading', true);
      this.newGuestForm.emited = true;
      const form = this.newGuestForm;
      form.phone = this.searchForm.phone;
      form.email = this.searchForm.email;

      this.$emit('newGuest', form);
    },
  },
};
</script>
