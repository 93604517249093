<template>
    <modal
        :show="show"
        :title="title"
        @save="close"
        saveText="OK"
        @close="close"
        :hideActionsCancel="true"
        :sticky-header="true"
    >
        <v-container v-if="reservationPreview">

            <h5 class="text-center text-h5 d-block mt-8 pb-0">{{ $t('Guest') }}</h5>
            <v-list class="py-0 px-4 px-sm-8">
                <v-list-item two-line class="pl-0 my-2">
                    <v-list-item-content>
                        <v-list-item-title class="mb-2">{{$t('Full name')}}</v-list-item-title>
                        <v-list-item-subtitle>
                          {{reservationPreview.guest.first_name}} {{reservationPreview.guest.last_name}}
                        </v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
                <template v-if="reservationPreview.ad.owner.id !== user.id || isAdmin">
                  <v-divider></v-divider>
                  <v-list-item two-line class="pl-0 my-2">
                      <v-list-item-content>
                          <v-list-item-title class="mb-2">{{ $t('Email')  }}</v-list-item-title>
                          <v-list-item-subtitle>{{ reservationPreview.guest.email }}</v-list-item-subtitle>
                      </v-list-item-content>
                  </v-list-item>
                </template>
                <v-divider></v-divider>
                <v-list-item
                  v-if="
                    (!reservationPreview.advance_payment || reservationPreview.advance_payment_paid)
                    || (reservationPreview.ad.owner.id !== user.id || isAdmin)
                  "
                  two-line
                  class="pl-0 my-2"
                >
                    <v-list-item-content>
                        <v-list-item-title class="mb-2">{{$t('Phone number')}}</v-list-item-title>
                        <v-list-item-subtitle>{{ reservationPreview.guest.phone }}</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
            </v-list>

            <h5 class="text-center text-h5 d-block mt-8 pb-0">{{ $t('Host') }}</h5>
            <v-list class="py-0 px-4 px-sm-8">
                <v-list-item two-line class="pl-0 my-2">
                    <v-list-item-content>
                        <v-list-item-title class="mb-2">{{$t('Full name')}}</v-list-item-title>
                        <v-list-item-subtitle>
                          <span class="mr-2">
                            {{ reservationPreview.ad.owner.first_name }} {{ reservationPreview.ad.owner.last_name }}
                          </span>

                          <template v-if="isAdmin">
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <a
                                  :href="getNovaUserUrl(reservationPreview.ad.owner.id)"
                                  target="_blank"
                                >
                                  <v-btn
                                    v-bind="attrs"
                                    v-on="on"
                                    icon
                                    color="primary"
                                  >
                                    <v-icon>mdi-account-outline</v-icon>
                                  </v-btn>
                                </a>
                              </template>
                              <span>{{ $t('Nova user link') }}</span>
                            </v-tooltip>

                            <v-tooltip bottom v-if="$route.name === 'reservations'">
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  v-bind="attrs"
                                  v-on="on"
                                  icon
                                  color="primary"
                                  :to="{ name: 'user-settings.account' }"
                                >
                                  <v-icon>mdi-cog-outline</v-icon>
                                </v-btn>
                              </template>
                              <span>{{ $t('Account Settings') }}</span>
                            </v-tooltip>
                          </template>
                        </v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
                <template v-if="reservationPreview.ad.owner.id === user.id || isAdmin">
                  <v-list-item two-line class="pl-0 my-2">
                      <v-list-item-content>
                          <v-list-item-title class="mb-2">{{ $t('Email')  }}</v-list-item-title>
                          <v-list-item-subtitle>{{ reservationPreview.ad.owner.email }}</v-list-item-subtitle>
                      </v-list-item-content>
                  </v-list-item>
                  <v-divider></v-divider>
                </template>
                <v-list-item
                  v-if="
                    (!reservationPreview.advance_payment || reservationPreview.advance_payment_paid)
                    || (reservationPreview.ad.owner.id === user.id || isAdmin)
                  "
                  two-line
                  class="pl-0 my-2"
                >
                    <v-list-item-content>
                        <v-list-item-title class="mb-2">{{$t('Phone number')}}</v-list-item-title>
                        <v-list-item-subtitle>{{ reservationPreview.ad.owner.phone }}</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>

                <!-- <template v-if="isAdmin">
                  <v-list-item two-line class="pl-0 my-2">
                      <v-list-item-content>
                          <v-list-item-title class="mb-2">{{$t('Nova user link')}}</v-list-item-title>
                          <v-list-item-subtitle>
                            <a :href="getNovaUserUrl(reservationPreview.ad.owner.id)" target="_blank">
                              {{ reservationPreview.ad.owner.first_name }} {{ reservationPreview.ad.owner.last_name }}
                            </a>
                          </v-list-item-subtitle>
                      </v-list-item-content>
                  </v-list-item>
                  <v-divider></v-divider>
                </template> -->
            </v-list>
            <div class="py-0 px-4 px-sm-8 mt-8">
              <h5 class="text-center text-h5 d-block mb-6 pb-0">{{ $t('Booking details') }}</h5>
              <reservation-event-details
                :event="reservationPreview"
                :is-admin="isAdmin"
                :ad-preview-url="getAdSinglePagePreviewUrl(reservationPreview.ad)"
                :show-owner-options="reservationPreview.ad.owner.id === user.id || isAdmin"
                :destination-search-url="getDestinationSearchUrl(
                  reservationPreview.ad.city.slug[$i18n.locale],
                  reservationPreview.ad.category.slug[$i18n.locale],
                )"
              ></reservation-event-details>
            </div>

            <h5 class="text-center text-h5 d-block mb-6 pb-0">{{ $t('Pricing Preview Title') }}</h5>
            <pricing-preview
                v-if="reservationPreview && reservationPreview.calculator"
                :key="`${reservationPreview.start}${reservationPreview.end}`"
                :show="true"
                :id="reservationPreview.ad_id.toString()"
                :start="reservationPreview.start"
                :end="reservationPreview.end"
                :customPrice="customPrice"
                :calculator-data="reservationPreview.calculator"
                :showInModal="false"
                :disable-filters="true"
            />
        </v-container>
    </modal>
</template>

<script>
import Modal from '@/components/Modal.vue';
import PricingPreview from '@/components/PricingPreview.vue';
import ReservationEventDetails from '@/components/reservations/ReservationEventDetails.vue';
import ApartmentMixin from '@/lib/ApartmentMixin';
import { fullDateFormat } from '@/components/calendar/config';
import { mapGetters } from 'vuex';

export default {
  mixins: [ApartmentMixin],
  components: {
    Modal,
    PricingPreview,
    ReservationEventDetails,
  },
  data() {
    return {
      show: false,
    };
  },
  watch: {
    reservationPreview(reservation) {
      if (reservation && reservation.id) {
        // load reservation from backend with all informations
        this.show = true;
      }
    },
  },
  computed: {
    ...mapGetters(['reservationPreview', 'isAdmin', 'user']),
    title() {
      if (this.reservationPreview && this.reservationPreview.booking_reference) {
        return `${this.$t('Reservation')} (${this.reservationPreview.booking_reference})`;
      }
      return this.$t('Reservation');
    },
    customPrice() {
      if (this.reservationPreview && this.reservationPreview.custom_pricing) {
        return {
          total: this.reservationPreview.total,
          serviceFee: this.reservationPreview.service_fee,
          taxOnServiceFee: this.reservationPreview.tax,
          ownerTotal: this.reservationPreview.owner_total,
        };
      }
      return undefined;
    },
  },
  methods: {
    fullDateFormat,
    close() {
      this.show = false;
      this.$store.dispatch('clearReservationPreview');
    },
  },
};
</script>

<style scoped lang="scss">

</style>
