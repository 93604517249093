var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{attrs:{"headers":_vm.headers,"header-props":{sortByText: _vm.$t('Sort by')},"items":_vm.reservations,"items-per-page":500,"hide-default-footer":"","fixed-header":"","disable-pagination":true},scopedSlots:_vm._u([{key:"item.start",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.fullDateFormat(item.start))+" ")]}},{key:"item.end",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.fullDateFormat(item.end))+" ")]}},{key:"item.guest",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.guest.first_name)+" "+_vm._s(item.guest.last_name)+" "),_c('br'),_vm._v(" "+_vm._s(_vm.$tc('guests_count', item.guests))+" ")]}},{key:"item.ad",fn:function(ref){
var item = ref.item;
return [(item && item.ad && item.ad.deleted_at === null)?_c('div',{staticClass:"d-flex align-center"},[_c('a',{attrs:{"href":_vm.getHref(item),"target":"_blank"}},[_vm._v(_vm._s(item.ad.name))])]):(item && item.ad && item.ad.name)?_c('div',[_vm._v(" "+_vm._s(item.ad.name)+" ")]):_c('div',[_vm._v(" "+_vm._s(_vm.$t('The apartment is no longer available for rent'))+" ")])]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.fullDateFormat(item.created_at, true))+" ")]}},{key:"item.advance_payment",fn:function(ref){
var item = ref.item;
return [(item.advance_payment)?[_c('div',{class:{'text-left mr-4 mr-md-0': item.advance_payment_paid}},[_c('span',{staticClass:"text-no-wrap"},[_vm._v(" € "+_vm._s(item.advance_payment_amount)+" ")])])]:_vm._e()]}},{key:"item.advance_payment_amount",fn:function(ref){
var item = ref.item;
return [(item.advance_payment)?[_c('div',{class:{'text-left mr-md-0': item.advance_payment_paid}},[_c('advance-payment-badge',{attrs:{"reservation":item}})],1)]:_vm._e()]}},{key:"item.total",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-no-wrap"},[_vm._v(" € "+_vm._s(item.total)+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c('v-btn',{staticClass:"text-decoration-underline  text-capitalize cursor-pointer mr-2",on:{"click":function($event){$event.stopPropagation();return _vm.loadReservationPreview(item.id)}}},[_vm._v(_vm._s(_vm.$t('Details')))]),(_vm.isAdmin)?_c('v-btn',{attrs:{"icon":"","color":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.editReservation(item.ad.id, item.id)}}},[_c('v-icon',[_vm._v("mdi-pencil-outline")])],1):_vm._e(),(
                ((_vm.user && _vm.user.id && item && item.ad && item.ad.owner_id === _vm.user.id) || _vm.isAdmin)
                && _vm.$route.name !== 'Calendar'
              )?_c('v-btn',{attrs:{"icon":"","color":"primary","to":{name: 'Calendar', params: {
                categoryConst: 'apartment',
                id: String(item.ad.id),
                focusEventId: item.id,
                focusEventStart: item.start,
              }}}},[_c('v-icon',[_vm._v("mdi-calendar")])],1):_vm._e(),(_vm.showCancelReservationButton(item) || _vm.showReportGuestNoShowButton(item))?_c('v-menu',{attrs:{"bottom":"","left":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"cursor-pointer text-capitalize rounded-xl",class:{'px-2 py-2' : _vm.$vuetify.breakpoint.mdAndDown},attrs:{"min-width":0,"elevation":"0","color":"black","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-dots-vertical ")])],1)]}}],null,true)},[_c('v-list',{attrs:{"dense":""}},[(_vm.showReportGuestNoShowButton(item))?_c('v-list-item',{on:{"click":function($event){$event.stopPropagation();return _vm.openGuestNoShowModal(item.id)}}},[_c('v-list-item-icon',{staticClass:"mr-2"},[_c('v-icon',[_vm._v("mdi-account-off-outline")])],1),_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t('Report guest no-show'))+" ")])],1):_vm._e(),(_vm.showCancelReservationButton(item))?_c('v-list-item',{on:{"click":function($event){$event.stopPropagation();return _vm.openGuestReservationCancellationModal(item.id)}}},[_c('v-list-item-icon',{staticClass:"mr-2"},[_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-close")])],1),_c('v-list-item-title',{staticClass:"red--text"},[_vm._v(" "+_vm._s(_vm.$t('Cancel reservation'))+" ")])],1):_vm._e()],1)],1):_vm._e()],1)]}}])}),(_vm.showGuestNoShowModal)?_c('guest-no-show-modal',{key:_vm.showGuestNoShowModalReservationId,attrs:{"show":_vm.showGuestNoShowModal,"reservationId":_vm.showGuestNoShowModalReservationId},on:{"close":function($event){_vm.showGuestNoShowModal = false; _vm.showGuestNoShowModalReservationId = undefined}}}):_vm._e(),(_vm.showGuestReservationCancellationModal)?_c('guest-reservation-cancellation-modal',{key:_vm.showGuestReservationCancellationModalReservationId,attrs:{"show":_vm.showGuestReservationCancellationModal,"reservationId":_vm.showGuestReservationCancellationModalReservationId},on:{"close":function($event){_vm.showGuestReservationCancellationModal = false; _vm.showGuestReservationCancellationModalReservationId = undefined}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }