<template>
  <modal
      :show="show"
      :title="$t('reservationCancelConfirmModal.title')"
      :description="$t('reservationCancelConfirmModal.description')"
      @save="submit"
      save-text="Confirm"
      @close="close"
      :loading="loading"
      :sticky-header="true"
      primary-button-class="red white--text"
      hide-top-close-button
      max-width="450"
  />
</template>

<script>
import Modal from '@/components/Modal.vue';
import axios from 'axios';
import { mapGetters } from 'vuex';

export default {
  props: {
    show: {
      required: true,
      type: Boolean,
    },
    reservationId: {
      required: true,
    },
  },
  components: {
    Modal,
  },
  data() {
    return {
    };
  },
  computed: {
    ...mapGetters(['loading']),
  },
  methods: {
    submit() {
      if (this.loading) {
        return;
      }
      this.$store.dispatch('loading', true);
      axios
        .post(`calendar/reservations/${this.reservationId}/send-guest-reservation-cancel-email`)
        .then(() => {
          this.$store.dispatch('message', this.$t('Reservation cancel requested'));
          this.close();
          this.$store.dispatch('loading', false);
        })
        .catch((error) => {
          if (error.response && error.response.data && error.response.data.errors) {
            this.$store.dispatch('setErrors', error.response.data.errors);
          }
          this.close();
          this.$store.dispatch('loading', false);
        });
    },
    close() {
      this.$emit('close');
    },
  },
};
</script>
