<template>
    <modal
        :show="show"
        :title="title"
        @save="close"
        saveText="OK"
        @close="close"
        :hideActionsCancel="true"
        :sticky-header="true"
        :loading="isLoadingResolveInquiry"
    >
        <v-container v-if="inquiryPreview">

          <errors-backend/>

          <div class="py-0 px-4 px-sm-8 mt-5 mb-5" v-if="!inquiryPreview.instant">
            <div v-if="!inquiryPreview.resolved">
              <v-alert v-if="inquiryPreview.owner_response === null && inquiryPreview.ad.owner.id === user.id" outlined color="black darken-2" class="rounded-lg pa-2">
                <div class="text-center">
                    <div class="d-flex flex-column mb-3">
                      <span class="font-weight-bold text-body-1">
                        {{ $t("Inquiry Owner Response Section Title") }}
                      </span>
                      <span class="text-body-2 ">
                        {{ $t("Inquiry Owner Response Section Description") }}
                      </span>
                    </div>

                    <div class="d-flex flex-column flex-sm-row justify-center">
                      <v-btn color="green"
                        class="white--text"
                        @click.stop="resolveInquiryOwner(1)"
                        :disabled="isLoadingResolveInquiry"
                      >
                        {{ $t("Available") }}
                      </v-btn>
                      <v-btn color="red"
                        class="white--text ml-sm-5 mt-4 mt-sm-0"
                        @click.stop="resolveInquiryOwner(0)"
                        :disabled="isLoadingResolveInquiry"
                      >
                        {{ $t("Not available") }}
                      </v-btn>
                  </div>
                </div>
              </v-alert>

              <!-- ceka se vlasnik da odgovori - status za gosta i agenciju -->
              <v-alert v-else-if="inquiryPreview.owner_response === null && inquiryPreview.ad.owner.id !== user.id" outlined color="black darken-2" class="rounded-lg pa-2">
                <div class="text-center">
                    <div class="d-flex flex-column">
                      <span class="font-weight-bold text-body-1">
                        {{ $t("Inquiry Guest Waiting For Owner Response Title") }}
                      </span>
                      <span class="text-body-2 ">
                        {{ $t("Inquiry Guest Waiting For Owner Response Subtitle") }}
                      </span>
                    </div>
                </div>
              </v-alert>
              <v-alert v-else-if="inquiryPreview.owner_response === 1" outlined class="rounded-lg pa-2 mt-5 mb-5" color="black darken-2">
                <!-- ovo je za gosta da odgovori -->
                <div class="d-flex align-center justify-center" v-if="inquiryPreview.guest_response === null && (inquiryPreview.user_id === user.id || inquiryPreview.email === user.email)">
                  <div class="d-flex text-center flex-column">

                    <span class="font-weight-bold text-body-1">
                      {{ $t("Inquiry Owner Response Available Title") }}.
                    </span>
                    <span class="text-body-2 ">
                      {{ $t("Inquiry Guest Response Available Subtitle") }}?
                    </span>
                    <div class="mt-5">
                      <v-btn color="green"
                        class="white--text"
                        @click.stop="resolveInquiryGuest(1)"
                        :disabled="isLoadingResolveInquiry"
                      >
                        {{ $t("Yes") }}
                      </v-btn>
                      <v-btn color="red"
                        class="white--text ml-5"
                        @click.stop="resolveInquiryGuest(0)"
                        :disabled="isLoadingResolveInquiry"
                      >
                        {{ $t("No") }}
                      </v-btn>
                    </div>
                  </div>
                </div>

                <!-- ceka se gost da odgovori - status za vlasnika i agenciju -->
                <div v-else-if="inquiryPreview.guest_response === null && inquiryPreview.user_id !== user.id" class="d-flex align-center justify-center" >
                  <div class="d-flex flex-column">
                    <span class="font-weight-bold text-body-1 d-flex flex-column">
                      <div class="mb-3 d-flex align-center">
                        <v-icon size="24" color="green" class="mr-2">mdi-check-circle-outline</v-icon>
                        {{$t("Inquiry Owner Response Available Title")}}. <br/>
                      </div>
                      <div class="d-flex align-center">
                        <v-icon size="24" color="black" class="mr-2">mdi-clock-time-eight-outline</v-icon>
                        {{$t("Inquiry Owner Response Available Subtitle")}}. <br/>
                      </div>
                    </span>
                  </div>
                </div>

                <div v-else-if="inquiryPreview.guest_response === 1" class="d-flex align-center justify-center" >
                  <div class="d-flex flex-column">
                    <span class="font-weight-bold text-body-1 d-flex flex-column">
                      <div class="mb-3 d-flex align-center">
                        <v-icon size="24" color="green" class="mr-2">mdi-check-circle-outline</v-icon>
                        {{$t("Inquiry Owner Response Available Title")}}. <br/>
                      </div>
                      <div class="mb-3 d-flex align-center">
                        <v-icon size="24" color="green" class="mr-2">mdi-check-circle-outline</v-icon>
                        {{$t("Inquiry Guest Response Confirm Reservation")}}. <br/>
                      </div>
                      <div class="d-flex align-center">
                        <v-icon size="24" color="black" class="mr-2">mdi-clock-time-eight-outline</v-icon>
                        {{$t("Inquiry Awaiting Confirm Reservation")}}. <br/>
                      </div>
                    </span>
                  </div>
                </div>

                <div v-else-if="inquiryPreview.guest_response === 0" class="d-flex align-center justify-center" >
                  <div class="d-flex flex-column">
                    <span class="font-weight-bold text-body-1 d-flex flex-column">
                      <div class="mb-3 d-flex align-center">
                        <v-icon size="24" color="green" class="mr-2">mdi-check-circle-outline</v-icon>
                        {{$t("Inquiry Owner Response Available Title")}}. <br/>
                      </div>
                      <div class="d-flex align-center">
                        <v-icon size="24" color="red" class="mr-2">mdi-cancel</v-icon>
                        {{$t("Inquiry Guest Response Cancel Reservation")}}. <br/>
                      </div>
                    </span>
                  </div>
                </div>
              </v-alert>
              <v-alert v-else-if="inquiryPreview.owner_response === 0" outlined class="rounded-lg pa-2 mt-5 mb-5" color="red darken-2">
                <div class="d-flex align-center justify-center">
                  <div>
                    <div class="d-flex flex-column">
                      <span class="font-weight-bold text-body-1">
                        {{ $t("Inquiry Owner Response Not Available") }}.
                      </span>
                    </div>
                  </div>
                </div>
              </v-alert>
            </div>

            <v-alert v-else-if="inquiryPreview.resolved && inquiryPreview.reservation" outlined class="rounded-lg pa-2">
              <div class="d-flex align-center justify-center font-weight-bold text-body-1">
                <v-icon size="24" color="green" class="mr-2">mdi-check-circle-outline</v-icon>
                {{$t("Reservation confirmed")}}.
              </div>
            </v-alert>
          </div>

            <h5 class="text-center text-h5 d-block mt-8 pb-0">{{ $t('Guest') }}</h5>
            <v-list class="py-0 px-4 px-sm-8">
                <v-list-item two-line class="pl-0 my-2">
                    <v-list-item-content>
                        <v-list-item-title class="mb-2">{{$t('Full name')}}</v-list-item-title>
                        <v-list-item-subtitle>{{ firstName }} {{ lastName }}</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
                <template v-if="inquiryPreview.ad.owner.id !== user.id || isAdmin">
                  <v-list-item two-line class="pl-0 my-2">
                      <v-list-item-content>
                          <v-list-item-title class="mb-2">{{ $t('Email') }}</v-list-item-title>
                          <v-list-item-subtitle>{{email}}</v-list-item-subtitle>
                      </v-list-item-content>
                  </v-list-item>
                  <v-divider></v-divider>
                  <v-list-item two-line class="pl-0 my-2">
                      <v-list-item-content>
                          <v-list-item-title class="mb-2">{{$t('Phone number')}}</v-list-item-title>
                          <v-list-item-subtitle>{{phone}}</v-list-item-subtitle>
                      </v-list-item-content>
                  </v-list-item>
                  <v-divider></v-divider>
                </template>
                <template v-if="inquiryPreview.message">
                  <v-list-item two-line class="pl-0 my-2" v-if="inquiryPreview.message">
                      <v-list-item-content>
                          <v-list-item-title class="mb-2">{{$t('Inquiry Message By Guest')}}</v-list-item-title>
                          <v-list-item-subtitle>{{inquiryPreview.message}}</v-list-item-subtitle>
                      </v-list-item-content>
                  </v-list-item>
                  <v-divider></v-divider>
                </template>
            </v-list>

            <h5 class="text-center text-h5 d-block mt-8 pb-0">{{ $t('Host') }}</h5>
            <v-list class="py-0 px-4 px-sm-8">
                <v-list-item two-line class="pl-0 my-2">
                    <v-list-item-content>
                        <v-list-item-title class="mb-2">{{$t('Full name')}}</v-list-item-title>
                        <v-list-item-subtitle>
                          <span class="mr-2">
                            {{ inquiryPreview.ad.owner.first_name }} {{ inquiryPreview.ad.owner.last_name }}
                          </span>

                          <template v-if="isAdmin">
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <a
                                  :href="getNovaUserUrl(inquiryPreview.ad.owner.id)"
                                  target="_blank"
                                  class="mr-2"
                                >
                                  <v-btn
                                    v-bind="attrs"
                                    v-on="on"
                                    icon
                                    color="primary"
                                  >
                                    <v-icon>mdi-account-outline</v-icon>
                                  </v-btn>
                                </a>
                              </template>
                              <span>{{ $t('Nova user link') }}</span>
                            </v-tooltip>

                            <v-tooltip bottom v-if="$route.name === 'inquiries'">
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  v-bind="attrs"
                                  v-on="on"
                                  icon
                                  color="primary"
                                  :to="{ name: 'user-settings.account' }"
                                >
                                  <v-icon>mdi-cog-outline</v-icon>
                                </v-btn>
                              </template>
                              <span>{{ $t('Account Settings') }}</span>
                            </v-tooltip>
                          </template>
                        </v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
                <template v-if="inquiryPreview.ad.owner.id === user.id || isAdmin">
                  <v-list-item two-line class="pl-0 my-2">
                      <v-list-item-content>
                          <v-list-item-title class="mb-2">{{$t('Email')}}</v-list-item-title>
                          <v-list-item-subtitle>{{ inquiryPreview.ad.owner.email }}</v-list-item-subtitle>
                      </v-list-item-content>
                  </v-list-item>
                  <v-divider></v-divider>
                  <v-list-item two-line class="pl-0 my-2">
                    <v-list-item-content>
                        <v-list-item-title class="mb-2">{{$t('Phone number')}}</v-list-item-title>
                        <v-list-item-subtitle>{{ inquiryPreview.ad.owner.phone }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider></v-divider>
                </template>
            </v-list>
            <div class="py-0 px-4 px-sm-8 mt-8">
              <h5 class="text-center text-h5 d-block mb-6 pb-0">{{ $t('Inquiry details') }}</h5>
              <reservation-event-details
                :event="inquiryPreview"
                :is-admin="isAdmin"
                :ad-preview-url="getAdSinglePagePreviewUrl(inquiryPreview.ad)"
                :show-owner-options="inquiryPreview.ad.owner.id === user.id || isAdmin"
                :destination-search-url="getDestinationSearchUrl(
                  inquiryPreview.ad.city.slug[$i18n.locale],
                  inquiryPreview.ad.category.slug[$i18n.locale],
                )"
              ></reservation-event-details>
            </div>

            <h5 class="text-center text-h5 d-block mb-6 pb-0">{{ $t('Pricing Preview Title') }}</h5>
            <pricing-preview
                v-if="inquiryPreview && inquiryPreview.calculator"
                :key="`${inquiryPreview.start}${inquiryPreview.end}`"
                :show="true"
                :id="inquiryPreview.ad_id.toString()"
                :start="inquiryPreview.start"
                :end="inquiryPreview.end"
                :customPrice="customPrice"
                :calculator-data="inquiryPreview.calculator"
                :showInModal="false"
                :disable-filters="true"
            />

            <v-alert v-if="isAdmin && !inquiryPreview.resolved" outlined class="rounded-lg mt-5 mb-5" color="black darken-2">
              <!-- ovo je za gosta da odgovori -->
              <div class="d-flex align-center justify-space-between space-between">
                  <span class="font-weight-bold text-body-1">
                    Da li želiš da označiš ovaj upit kao rešen?
                  </span>
                    <v-btn color="green"
                      small
                      class="white--text"
                      @click.stop="setInquiryAsResolved()"
                      :disabled="isLoadingResolveInquiry"
                    >
                      {{ $t("Yes") }}
                    </v-btn>
              </div>
            </v-alert>

        </v-container>
    </modal>
</template>

<script>
import axios from 'axios';
import Modal from '@/components/Modal.vue';
import PricingPreview from '@/components/PricingPreview.vue';
import ReservationEventDetails from '@/components/reservations/ReservationEventDetails.vue';
import ApartmentMixin from '@/lib/ApartmentMixin';
import { fullDateFormat } from '@/components/calendar/config';
import { mapGetters } from 'vuex';

export default {
  mixins: [ApartmentMixin],
  components: {
    Modal,
    PricingPreview,
    ReservationEventDetails,
  },
  data() {
    return {
      show: false,
      isLoadingResolveInquiry: false,
    };
  },
  watch: {
    inquiryPreview(inquiry) {
      if (inquiry && inquiry.id) {
        // load Inquiry from backend with all informations
        this.show = true;
      }
    },
  },
  computed: {
    ...mapGetters(['inquiryPreview', 'isAdmin', 'user']),
    title() {
      if (this.inquiryPreview && this.inquiryPreview.booking_reference) {
        return `${this.$t('Inquiry')} (${this.inquiryPreview.booking_reference})`;
      }
      return this.$t('Inquiry');
    },
    firstName() {
      if (this.inquiryPreview && this.inquiryPreview.user && this.inquiryPreview.user.first_name) {
        return this.inquiryPreview.user.first_name;
      }
      return this.inquiryPreview.first_name;
    },
    lastName() {
      if (this.inquiryPreview && this.inquiryPreview.user && this.inquiryPreview.user.last_name) {
        return this.inquiryPreview.user.last_name;
      }
      return this.inquiryPreview.last_name;
    },
    email() {
      if (this.inquiryPreview && this.inquiryPreview.user && this.inquiryPreview.user.email) {
        return this.inquiryPreview.user.email;
      }
      return this.inquiryPreview.email;
    },
    phone() {
      if (this.inquiryPreview && this.inquiryPreview.user && this.inquiryPreview.user.phone) {
        return this.inquiryPreview.user.phone;
      }
      return this.inquiryPreview.phone;
    },
    customPrice() {
      if (this.inquiryPreview && this.inquiryPreview.custom_pricing) {
        return {
          total: this.inquiryPreview.total,
          serviceFee: this.inquiryPreview.service_fee,
          taxOnServiceFee: this.inquiryPreview.tax,
          ownerTotal: this.inquiryPreview.owner_total,
        };
      }
      return undefined;
    },
  },
  methods: {
    fullDateFormat,
    close() {
      this.show = false;
      this.$store.dispatch('clearInquiryPreview');
      this.$store.dispatch('clearErrors');
    },
    resolveInquiryOwner(replay) {
      this.isLoadingResolveInquiry = true;
      axios
        .post(`/calendar/inquries/owner-response/${this.inquiryPreview.id}`, {
          owner_response: replay,
        })
        .then((res) => {
          this.isLoadingResolveInquiry = false;
          console.log('stigao response');
          console.log(res);
          this.$store.dispatch('message', this.$t('Responded to inquiry successfuly'));
          this.$store.dispatch('clearErrors');
          this.$store.dispatch('setInquiryPreview', this.inquiryPreview.id);
          this.$emit('reload');
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
          this.isLoadingResolveInquiry = false;
          this.$store.dispatch('message', this.$t('Error'));
          this.$store.dispatch('setErrors', error.response.data.errors);
        });
    },
    resolveInquiryGuest(replay) {
      this.isLoadingResolveInquiry = true;
      axios
        .post(`/calendar/inquries/guest-response/${this.inquiryPreview.id}`, {
          guest_response: replay,
        })
        .then(() => {
          this.isLoadingResolveInquiry = false;

          this.$store.dispatch('message',
            replay === 1
              ? this.$t('Accommodation booked successfuly')
              : this.$t('Reservation was not made'));

          this.$store.dispatch('clearErrors');
          this.$store.dispatch('setInquiryPreview', this.inquiryPreview.id);
          this.$emit('reload');
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
          this.isLoadingResolveInquiry = false;
          this.$store.dispatch('message', this.$t('Error'));
          this.$store.dispatch('setErrors', error.response.data.errors);
        });
    },
    setInquiryAsResolved() {
      this.isLoadingResolveInquiry = true;
      axios
        .post(`/calendar/inquries/set-resolved/${this.inquiryPreview.id}`)
        .then(() => {
          this.isLoadingResolveInquiry = false;

          this.$store.dispatch('message', 'Inquiry marked as RESOLVED');

          this.$store.dispatch('clearErrors');

          this.show = false;
          // this.$store.dispatch('setInquiryPreview', this.inquiryPreview.id);
          this.$emit('reload');
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
          this.isLoadingResolveInquiry = false;
          this.$store.dispatch('message', this.$t('Error'));
          this.$store.dispatch('setErrors', error.response.data.errors);
        });
    },
  },
};
</script>

<style scoped lang="scss">

</style>
