<template>
    <div>
        <v-data-table
            :headers="headers"
            :header-props="{sortByText: $t('Sort by')}"
            :items="reservations"
            :items-per-page="500"
            hide-default-footer
            fixed-header
            :disable-pagination="true"
        >
            <template v-slot:item.start="{ item }">
                {{ fullDateFormat(item.start) }}
            </template>
            <template v-slot:item.end="{ item }">
                {{ fullDateFormat(item.end) }}
            </template>
            <template v-slot:item.guest="{ item }">
                {{ item.guest.first_name }} {{ item.guest.last_name }}
                <br/>
                {{ $tc('guests_count', item.guests) }}
            </template>
            <template v-slot:item.ad="{ item }">
              <div class="d-flex align-center" v-if="item && item.ad && item.ad.deleted_at === null">
                <a :href="getHref(item)" target="_blank">{{item.ad.name}}</a>
              </div>
              <div v-else-if="item && item.ad && item.ad.name">
                  {{item.ad.name}}
                </div>
                <div v-else>
                  {{$t('The apartment is no longer available for rent')}}
                </div>
            </template>
            <template v-slot:item.created_at="{ item }">
                {{fullDateFormat(item.created_at, true)}}
            </template>
            <template v-slot:item.advance_payment="{ item }">
                <template v-if="item.advance_payment">
                  <div :class="{'text-left mr-4 mr-md-0': item.advance_payment_paid}">
                    <span class="text-no-wrap">
                      € {{ item.advance_payment_amount }}
                    </span>
                  </div>
                </template>
            </template>
            <template v-slot:item.advance_payment_amount="{ item }">
                <template v-if="item.advance_payment">
                  <div :class="{'text-left mr-md-0': item.advance_payment_paid}">
                    <advance-payment-badge :reservation="item"></advance-payment-badge>
                  </div>
                </template>
            </template>
            <template v-slot:item.total="{ item }">
              <span class="text-no-wrap">
                € {{ item.total }}
              </span>
            </template>
            <template v-slot:item.actions="{ item }">
              <div class="d-flex">
                <v-btn
                    class="text-decoration-underline  text-capitalize cursor-pointer mr-2"
                    @click.stop="loadReservationPreview(item.id)">{{ $t('Details') }}</v-btn>
                <v-btn
                  v-if="isAdmin"
                  icon
                  color="primary"
                  @click.stop="editReservation(item.ad.id, item.id)"
                >
                  <v-icon>mdi-pencil-outline</v-icon>
                </v-btn>
                <v-btn
                  v-if="
                    ((user && user.id && item && item.ad && item.ad.owner_id === user.id) || isAdmin)
                    && $route.name !== 'Calendar'
                  "
                  icon
                  color="primary"
                  :to="{name: 'Calendar', params: {
                    categoryConst: 'apartment',
                    id: String(item.ad.id),
                    focusEventId: item.id,
                    focusEventStart: item.start,
                  }}"
                >
                  <v-icon>mdi-calendar</v-icon>
                </v-btn>

                <v-menu
                  v-if="showCancelReservationButton(item) || showReportGuestNoShowButton(item)"
                  bottom
                  left
                  offset-y
                >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    :class="{'px-2 py-2' : $vuetify.breakpoint.mdAndDown}"
                    :min-width="0"
                    v-bind="attrs"
                    v-on="on"
                    class="cursor-pointer text-capitalize rounded-xl"
                    elevation="0"
                    color="black"
                    icon
                  >
                    <v-icon>
                      mdi-dots-vertical
                    </v-icon>
                  </v-btn>
                </template>
                  <v-list dense>
                    <v-list-item
                      v-if="showReportGuestNoShowButton(item)"
                      @click.stop="openGuestNoShowModal(item.id)"
                    >
                      <v-list-item-icon class="mr-2">
                        <v-icon>mdi-account-off-outline</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>
                        {{ $t('Report guest no-show') }}
                      </v-list-item-title>
                    </v-list-item>

                    <v-list-item
                      v-if="showCancelReservationButton(item)"
                      @click.stop="openGuestReservationCancellationModal(item.id)"
                    >
                      <v-list-item-icon class="mr-2">
                        <v-icon color="red">mdi-close</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title class="red--text">
                        {{ $t('Cancel reservation') }}
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
            </template>

        </v-data-table>

        <guest-no-show-modal
          v-if="showGuestNoShowModal"
          :show="showGuestNoShowModal"
          @close="showGuestNoShowModal = false; showGuestNoShowModalReservationId = undefined"
          :reservationId="showGuestNoShowModalReservationId"
          :key="showGuestNoShowModalReservationId"
        />

        <guest-reservation-cancellation-modal
          v-if="showGuestReservationCancellationModal"
          :show="showGuestReservationCancellationModal"
          @close="showGuestReservationCancellationModal = false; showGuestReservationCancellationModalReservationId = undefined"
          :reservationId="showGuestReservationCancellationModalReservationId"
          :key="showGuestReservationCancellationModalReservationId"
        />

    </div>
</template>

<script>
import { fullDateFormat } from '@/components/calendar/config';
import moment from 'moment';
import AdvancePaymentBadge from '@/components/reservations/AdvancePaymentBadge.vue';
import { mapGetters } from 'vuex';
import GuestNoShowModal from '@/components/reservations/GuestNoShowModal.vue';
import GuestReservationCancellationModal from '@/components/reservations/GuestReservationCancellationModal.vue';

export default {
  props: {
    reservations: {
      required: true,
      type: Array,
    },
  },
  components: {
    AdvancePaymentBadge,
    GuestNoShowModal,
    GuestReservationCancellationModal,
  },
  data() {
    return {
      showGuestNoShowModal: false,
      showGuestNoShowModalReservationId: undefined,
      showGuestReservationCancellationModal: false,
      showGuestReservationCancellationModalReservationId: undefined,
    };
  },
  computed: {
    ...mapGetters(['isAdmin', 'user']),
    headers() {
      return [
        { text: this.$t('Reservation Listing'), value: 'ad' },
        { text: this.$t('Check-in'), value: 'start' },
        { text: this.$t('Check-out'), value: 'end' },
        { text: this.$t('Guest'), value: 'guest' },

        { text: this.$t('Upfront payment'), value: 'advance_payment' },
        { text: this.$t('Advance payment due date'), value: 'advance_payment_amount' },
        { text: this.$t('Booking date'), value: 'created_at' },

        { text: this.$t('Total'), value: 'total' },
        { text: this.$t('Booking reference'), value: 'booking_reference' },
        { text: '', value: 'actions' },
      ];
    },
  },
  methods: {
    openGuestNoShowModal(id) {
      this.showGuestNoShowModalReservationId = id;
      this.showGuestNoShowModal = true;
    },
    openGuestReservationCancellationModal(id) {
      this.showGuestReservationCancellationModalReservationId = id;
      this.showGuestReservationCancellationModal = true;
    },
    fullDateFormat,
    loadReservationPreview(id) {
      this.$store.dispatch('setReservationPreview', id);
    },
    editReservation(apartmentId, reservationId) {
      this.$store.dispatch('editReservationId', undefined);
      this.$router.push({
        name: 'EditReservation',
        params: {
          id: String(apartmentId),
          categoryConst: 'apartment',
          reservationId: String(reservationId),
        },
      });
    },
    showReportGuestNoShowButton(item) {
      return ((item.ad && item.ad.owner_id === this.user.id) || this.isAdmin) && moment().isBetween(
        moment(item.start).startOf('day'),
        moment(item.start).add(24, 'hours').endOf('day'),
      );
    },
    showCancelReservationButton(item) {
      return (item.guest_id && item.guest_id === this.user.id) && moment().isBefore(moment(item.start).startOf('day'));
    },
    getHref(item) {
      if (item.ad.id && Boolean(item.ad.active[this.$i18n.locale])) {
        return `${process.env.VUE_APP_APP_URL}/${this.$i18n.locale === 'en' ? '' : `${this.$i18n.locale}/`}ad/${item.ad.id}`;
      }
      if (item.ad.id) {
        return `${process.env.VUE_APP_APP_URL}/${this.$i18n.locale === 'en' ? '' : `${this.$i18n.locale}/`}preview/${item.ad.id}`;
      }
      return undefined;
    },
  },
};

</script>
