<template>
    <div>
        <v-data-table
            :headers="headers"
            :header-props="{sortByText: $t('Sort by')}"
            :items="inquries"
            :items-per-page="500"
            hide-default-footer
            fixed-header
            :disable-pagination="true"
        >
            <template v-slot:item.start="{ item }">
                {{ fullDateFormat(item.start) }}
            </template>
            <template v-slot:item.end="{ item }">
                {{ fullDateFormat(item.end) }}
            </template>
            <template v-slot:item.guest="{ item }">
                <template v-if="item.user">
                  {{ item.user.first_name }} {{ item.user.last_name }}
                </template>
                <template v-else>
                  {{ item.first_name }} {{ item.last_name }} | {{$t('Guest')}}
                </template>
                <br/>
                {{ $tc('guests_count', item.guests) }}
            </template>
            <template v-slot:item.status="{ item }">
              <v-tooltip bottom :disabled="!item.reservation || $vuetify.breakpoint.smAndDown">
                <template v-slot:activator="{ on, attrs }">
                  <v-chip
                    :color="item.status.color"
                    class="white--text px-2"
                    style="height: 26px;"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <span class="mr-1 cursor-pointer"
                      v-if="item.reservation && item.reservation.id"
                      @click.stop="loadReservationPreview(item.reservation.id)"
                    >{{item.status[$i18n.locale]}}</span>
                    <span v-else class="mr-1">{{item.status[$i18n.locale]}}</span>
                  </v-chip>
                </template>
                <span>{{ $t('See reservation details') }}</span>
              </v-tooltip>
            </template>
            <!-- <template v-slot:item.owner_response="{ item }">
                <div :class="{'text-left mr-4 mr-md-0': item.advance_payment_paid}">
                  <owner-response-badge :inquiry="item"></owner-response-badge>
                </div>
            </template>
            <template v-slot:item.guest_response="{ item }">
                <div>
                  <v-badge v-if="item.guest_response !== null && item.guest_response && item.guest_response === 1" color="green">
                    <template v-slot:badge>
                        <span class="mr-1">{{ $t('Yes') }}</span>
                    </template>
                </v-badge>
                <v-badge v-else-if="item.guest_response !== null && item.guest_response === 0" color="red">
                    <template v-slot:badge>
                        <span class="mr-1">{{ $t('No') }}</span>
                    </template>
                </v-badge>
                </div>
            </template> -->
            <template v-slot:item.ad="{ item }">
                <div class="d-flex align-center" v-if="item && item.ad && item.ad.deleted_at === null">
                    <a :href="getHref(item)" target="_blank">{{item.ad.name}}</a>

                  </div>
                <div v-else-if="item && item.ad && item.ad.name">
                  {{item.ad.name}}
                </div>
                <div v-else>
                  {{$t('The apartment is no longer available for rent')}}
                </div>
            </template>
            <template v-slot:item.created_at="{ item }">
                {{fullDateFormat(item.created_at, true)}}
            </template>
            <template v-slot:item.total="{ item }">
              <span class="text-no-wrap">
                € {{ item.total }}
              </span>
            </template>
            <template v-slot:item.actions="{ item }">
              <div class="d-flex align-center" v-if="item && item.ad && item.ad.deleted_at === null">
                <v-btn
                    class="text-decoration-underline  text-capitalize cursor-pointer mr-2"
                    @click.stop="loadInquiryPreview(item.id)">{{ $t('Details') }}</v-btn>
                <v-btn
                  v-if="(user && user.id && item && item.ad && item.ad.owner_id === user.id) || isAdmin"
                  icon
                  class="mr-2"
                  color="primary"
                  :to="{name: 'Calendar', params: {
                    categoryConst: 'apartment',
                    id: String(item.ad.id),
                    focusEventId: item.reservation ? item.reservation.id : null,
                    focusEventStart: item.reservation ? item.reservation.start : null,
                  }}"
                >
                  <v-icon>mdi-calendar</v-icon>
                </v-btn>
                <!-- <v-btn
                  v-if="(user && user.id && user.id && item && item.ad && item.ad.owner_id === user.id) || isAdmin"
                  icon
                  color="primary"
                  :to="{name: 'Calendar', params: {
                    categoryConst: 'apartment',
                    id: String(item.ad.id)
                  }}"
                >
                  <v-icon>mdi-pencil-outline</v-icon>
                </v-btn> -->

                <v-btn
                  v-if="isAdmin && item.reservation === null"
                  small
                  outlined
                  @click.stop="openReservationModal(item)"
                >reserve</v-btn>
              </div>
            </template>
            <!-- <template v-slot:item.details="{ item }">
                <v-btn
                    class="text-decoration-underline  text-capitalize cursor-pointer"
                    @click.stop="loadInquiryPreview(item.id)">{{ $t('Details') }}</v-btn>
            </template> -->

        </v-data-table>

        <reservation-modal

          v-if="selectedInquiry && selectedInquiry.ad_id"
          :id="selectedInquiry.ad_id"
          :key="reservationModalKey"
          :makeReservationFromInquiry="selectedInquiry"
          @loadReservationInSidebar="$emit('loadReservationInSidebar', $event)"
          @inquiryToReservationSaved="$emit('inquiryToReservationSaved')"
        />
    </div>
</template>

<script>
import { fullDateFormat } from '@/components/calendar/config';
// import AdvancePaymentBadge from '@/components/reservations/AdvancePaymentBadge.vue';
// import OwnerResponseBadge from '@/components/inquries/OwnerResponseBadge.vue';

import ReservationModal from '@/components/reservations/ReservationModal.vue';
import { mapGetters } from 'vuex';

export default {
  components: { ReservationModal },
  props: {
    inquries: {
      required: true,
      type: Array,
    },
  },
  data() {
    return {
      selectedInquiry: undefined,
      reservationModalKey: 1,
    };
  },
  computed: {
    ...mapGetters(['isAdmin', 'user']),
    status() {
      return '';
    },
    headers() {
      return [
        // { text: 'Status', value: 'status', width: 240 },
        { text: this.$t('Reservation Listing'), value: 'ad' },
        { text: 'Status', value: 'status' },
        { text: this.$t('Check-in'), value: 'start' },
        { text: this.$t('Check-out'), value: 'end' },
        { text: this.$t('Guest'), value: 'guest' },

        // { text: this.$t('Owner Response'), value: 'owner_response' },
        // { text: this.$t('Guest Response'), value: 'guest_response' },

        { text: this.$t('Inquiry date'), value: 'created_at' },

        { text: this.$t('Total'), value: 'total' },
        { text: this.$t('Inquiry reference'), value: 'booking_reference' },
        { text: '', value: 'actions' },
        // { text: '', value: 'details' },
      ];
    },
  },
  methods: {
    fullDateFormat,
    getHref(item) {
      if (item.ad.id && Boolean(item.ad.active[this.$i18n.locale])) {
        return `${process.env.VUE_APP_APP_URL}/${this.$i18n.locale === 'en' ? '' : `${this.$i18n.locale}/`}ad/${item.ad.id}`;
      }
      if (item.ad.id) {
        return `${process.env.VUE_APP_APP_URL}/${this.$i18n.locale === 'en' ? '' : `${this.$i18n.locale}/`}preview/${item.ad.id}`;
      }
      return undefined;
    },
    openReservationModal(item) {
      this.selectedInquiry = { ...item, ad_id: item.ad_id.toString(), id: item.id.toString() };
      this.$store.commit('toggleReservationModal');
      this.reservationModalKey += 1;
    },
    loadInquiryPreview(id) {
      this.$store.dispatch('setInquiryPreview', id);
    },
    loadReservationPreview(id) {
      this.$store.dispatch('setReservationPreview', id);
    },
    // getStatus(item) {
    //   if (item.owner_response === null) {
    //     return 'Čeka se vlasnik';
    //   }
    //   if (item.owner_response === 0) {
    //     return 'Vlasnik odbio upit';
    //   }
    //   if (item.owner_response === 1 && item.guest_response === null) {
    //     return 'Vlasnik potvrdio čeka se gost';
    //   }
    //   if (item.owner_response === 1 && item.guest_response === 1) {
    //     return 'Vlasnik potvrdio + Gost potvrdio, čeka se BW';
    //   }
    //   if (item.owner_response === 1 && item.guest_response === 0) {
    //     return 'Vlasnik potvrdio + Gost ne želi';
    //   }
    //   return '';
    // },
  },
};

</script>
