<template>
    <div class="d-flex align-center">
      <span class="font-weight-medium text-body-1 mr-auto">{{ label }}</span>
      <div>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              @click.prevent="onClick(false)"
              :class="{'disable-events': !value}"
              :color="!value ? 'red' : 'black'"
              :dark="!value"
              :outlined="value"
              v-bind="attrs"
              v-on="on"
              class="elevation-0"
              fab
              x-small
            >
              <v-icon>mdi-window-close</v-icon>
            </v-btn>
          </template>
          <span>No</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              @click.prevent="onClick(true)"
              :class="{'disable-events': value}"
              :color="value ? 'green' : 'black'"
              :dark="value"
              :outlined="!value"
              v-bind="attrs"
              v-on="on"
              class="ml-4 elevation-0"
              fab
              x-small
            >
              <v-icon dark>mdi-check</v-icon>
            </v-btn>
          </template>
          <span>Yes</span>
        </v-tooltip>
      </div>
    </div>
  </template>

<script>
export default {
  props: {
    label: {
      type: String,
      required: true,
    },
    value: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    onClick(val) {
      this.$emit('input', val);
    },
  },
};
</script>
