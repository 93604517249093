var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('modal',{attrs:{"show":_vm.show,"title":_vm.title,"saveText":"OK","hideActionsCancel":true,"sticky-header":true},on:{"save":_vm.close,"close":_vm.close}},[(_vm.reservationPreview)?_c('v-container',[_c('h5',{staticClass:"text-center text-h5 d-block mt-8 pb-0"},[_vm._v(_vm._s(_vm.$t('Guest')))]),_c('v-list',{staticClass:"py-0 px-4 px-sm-8"},[_c('v-list-item',{staticClass:"pl-0 my-2",attrs:{"two-line":""}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"mb-2"},[_vm._v(_vm._s(_vm.$t('Full name')))]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(_vm.reservationPreview.guest.first_name)+" "+_vm._s(_vm.reservationPreview.guest.last_name)+" ")])],1)],1),(_vm.reservationPreview.ad.owner.id !== _vm.user.id || _vm.isAdmin)?[_c('v-divider'),_c('v-list-item',{staticClass:"pl-0 my-2",attrs:{"two-line":""}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"mb-2"},[_vm._v(_vm._s(_vm.$t('Email')))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(_vm.reservationPreview.guest.email))])],1)],1)]:_vm._e(),_c('v-divider'),(
                (!_vm.reservationPreview.advance_payment || _vm.reservationPreview.advance_payment_paid)
                || (_vm.reservationPreview.ad.owner.id !== _vm.user.id || _vm.isAdmin)
              )?_c('v-list-item',{staticClass:"pl-0 my-2",attrs:{"two-line":""}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"mb-2"},[_vm._v(_vm._s(_vm.$t('Phone number')))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(_vm.reservationPreview.guest.phone))])],1)],1):_vm._e(),_c('v-divider')],2),_c('h5',{staticClass:"text-center text-h5 d-block mt-8 pb-0"},[_vm._v(_vm._s(_vm.$t('Host')))]),_c('v-list',{staticClass:"py-0 px-4 px-sm-8"},[_c('v-list-item',{staticClass:"pl-0 my-2",attrs:{"two-line":""}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"mb-2"},[_vm._v(_vm._s(_vm.$t('Full name')))]),_c('v-list-item-subtitle',[_c('span',{staticClass:"mr-2"},[_vm._v(" "+_vm._s(_vm.reservationPreview.ad.owner.first_name)+" "+_vm._s(_vm.reservationPreview.ad.owner.last_name)+" ")]),(_vm.isAdmin)?[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('a',{attrs:{"href":_vm.getNovaUserUrl(_vm.reservationPreview.ad.owner.id),"target":"_blank"}},[_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"primary"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-account-outline")])],1)],1)]}}],null,false,1517739756)},[_c('span',[_vm._v(_vm._s(_vm.$t('Nova user link')))])]),(_vm.$route.name === 'reservations')?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"primary","to":{ name: 'user-settings.account' }}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-cog-outline")])],1)]}}],null,false,2053836068)},[_c('span',[_vm._v(_vm._s(_vm.$t('Account Settings')))])]):_vm._e()]:_vm._e()],2)],1)],1),_c('v-divider'),(_vm.reservationPreview.ad.owner.id === _vm.user.id || _vm.isAdmin)?[_c('v-list-item',{staticClass:"pl-0 my-2",attrs:{"two-line":""}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"mb-2"},[_vm._v(_vm._s(_vm.$t('Email')))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(_vm.reservationPreview.ad.owner.email))])],1)],1),_c('v-divider')]:_vm._e(),(
                (!_vm.reservationPreview.advance_payment || _vm.reservationPreview.advance_payment_paid)
                || (_vm.reservationPreview.ad.owner.id === _vm.user.id || _vm.isAdmin)
              )?_c('v-list-item',{staticClass:"pl-0 my-2",attrs:{"two-line":""}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"mb-2"},[_vm._v(_vm._s(_vm.$t('Phone number')))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(_vm.reservationPreview.ad.owner.phone))])],1)],1):_vm._e(),_c('v-divider')],2),_c('div',{staticClass:"py-0 px-4 px-sm-8 mt-8"},[_c('h5',{staticClass:"text-center text-h5 d-block mb-6 pb-0"},[_vm._v(_vm._s(_vm.$t('Booking details')))]),_c('reservation-event-details',{attrs:{"event":_vm.reservationPreview,"is-admin":_vm.isAdmin,"ad-preview-url":_vm.getAdSinglePagePreviewUrl(_vm.reservationPreview.ad),"show-owner-options":_vm.reservationPreview.ad.owner.id === _vm.user.id || _vm.isAdmin,"destination-search-url":_vm.getDestinationSearchUrl(
              _vm.reservationPreview.ad.city.slug[_vm.$i18n.locale],
              _vm.reservationPreview.ad.category.slug[_vm.$i18n.locale]
            )}})],1),_c('h5',{staticClass:"text-center text-h5 d-block mb-6 pb-0"},[_vm._v(_vm._s(_vm.$t('Pricing Preview Title')))]),(_vm.reservationPreview && _vm.reservationPreview.calculator)?_c('pricing-preview',{key:("" + (_vm.reservationPreview.start) + (_vm.reservationPreview.end)),attrs:{"show":true,"id":_vm.reservationPreview.ad_id.toString(),"start":_vm.reservationPreview.start,"end":_vm.reservationPreview.end,"customPrice":_vm.customPrice,"calculator-data":_vm.reservationPreview.calculator,"showInModal":false,"disable-filters":true}}):_vm._e()],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }