<template>
  <v-list class="py-0">
      <template v-if="event && event.ad">
        <v-list-item two-line class="pl-0 my-2">
            <v-list-item-content>
                <v-list-item-title class="mb-2">{{ $t('Apartment') }}</v-list-item-title>
                <v-list-item-subtitle v-if="event && event.ad && event.ad.deleted_at !== null">
                  {{event.ad.name}}
                </v-list-item-subtitle>
                <v-list-item-subtitle v-else-if="event && event.ad && event.ad.id">
                  <router-link
                    v-if="showOwnerOptions"
                    :to="{ name: 'AdForm', params: {
                      id: event.ad.id.toString(),
                      categoryConst: 'apartment',
                    }}"
                    class="mr-2"
                  >
                    {{event.ad.name}}
                  </router-link>
                  <a v-else :href="adPreviewUrl">{{event.ad.name}}</a>

                  <v-btn
                    v-if="showOwnerOptions && $route.name !== 'Calendar'"
                    icon
                    color="primary ml-3"
                    :to="{name: 'Calendar', params: {
                      categoryConst: 'apartment',
                      id: String(event.ad.id)
                    }}"
                  >
                    <v-icon>mdi-calendar</v-icon>
                  </v-btn>
                  <v-tooltip bottom v-if="adPreviewUrl && showOwnerOptions">
                    <template v-slot:activator="{ on, attrs }">
                      <a
                        :href="adPreviewUrl"
                        target="_blank"
                      >
                        <v-btn
                          icon
                          color="primary"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-icon>mdi-eye-outline</v-icon>
                        </v-btn>
                      </a>
                    </template>
                    <span>{{$t('View')}}</span>
                  </v-tooltip>
                </v-list-item-subtitle>
            </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
      </template>

      <template v-if="isAdmin && destinationSearchUrl">
        <v-list-item two-line class="pl-0 my-2">
            <v-list-item-content>
                <v-list-item-title class="mb-2">{{ $t('Same destination search') }}</v-list-item-title>
                <v-list-item-subtitle>
                  <a :href="destinationSearchUrl" class="text-decoration-underline">{{ $t('Apartments') }}</a>
                </v-list-item-subtitle>
            </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
      </template>

      <v-list-item two-line class="pl-0 my-2">
          <v-list-item-content>
              <v-list-item-title class="mb-2">{{ $t('Price') }}</v-list-item-title>
              <v-list-item-subtitle>€ {{event.total}}</v-list-item-subtitle>
          </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
      <template v-if="event.advance_payment">
          <v-list-item three-line class="pl-0 my-2">
              <v-list-item-content>
                  <v-list-item-title class="mb-2">{{$t('Upfront payment')}}</v-list-item-title>
                  <v-list-item-subtitle>
                      € {{ event.advance_payment_amount }}
                  </v-list-item-subtitle>
                  <v-list-item-subtitle class="pa-1 pb-0">
                      <advance-payment-badge :reservation="event"></advance-payment-badge>
                  </v-list-item-subtitle>
              </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item two-line class="pl-0 my-2" v-if="paymentLeftover">
              <v-list-item-content>
                  <v-list-item-title class="mb-2">{{ $t('Remaining payment') }}</v-list-item-title>
                  <v-list-item-subtitle>
                      € {{ paymentLeftover }}
                  </v-list-item-subtitle>
              </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>
      </template>
      <v-list-item two-line class="pl-0 my-2">
          <v-list-item-content>
              <v-list-item-title class="mb-2">{{ $t('Form Property Guests') }}</v-list-item-title>
              <v-list-item-subtitle>{{event.guests}}</v-list-item-subtitle>
          </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
      <v-list-item two-line class="pl-0 my-2">
          <v-list-item-content>
            <v-list-item-title class="mb-2">{{ $t('Check-in') }}</v-list-item-title>
            <v-list-item-subtitle>{{fullDateFormat(event.start)}}</v-list-item-subtitle>
          </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
      <v-list-item two-line class="pl-0 my-2">
          <v-list-item-content>
            <v-list-item-title class="mb-2">{{ $t('Check-out') }}</v-list-item-title>
            <v-list-item-subtitle>{{fullDateFormat(event.end)}}</v-list-item-subtitle>
          </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
      <v-list-item two-line class="pl-0 my-2">
          <v-list-item-content>
              <v-list-item-title class="mb-2">Parking</v-list-item-title>
              <v-list-item-subtitle>{{ $t(event.parking ? 'parking.with' : 'parking.without') }}</v-list-item-subtitle>
          </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
      <v-list-item two-line class="pl-0 my-2">
          <v-list-item-content>
              <v-list-item-title class="mb-2">{{ event.type === 'reservation' ? $t('Booking date') : $t('Inquiry date') }}</v-list-item-title>
              <v-list-item-subtitle>{{fullDateFormat(event.created_at, true)}}</v-list-item-subtitle>
          </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
      <v-list-item two-line class="pl-0 my-2">
          <v-list-item-content>
              <v-list-item-title class="mb-2">{{ event.type === 'reservation' ? $t('Reservation code') : $t('Inquiry code') }}</v-list-item-title>
              <v-list-item-subtitle>{{event.booking_reference}}</v-list-item-subtitle>
          </v-list-item-content>
      </v-list-item>

      <template v-if="isAdmin && event.comment">
          <v-divider></v-divider>
          <v-list-item class="pl-0 my-2" >
              <v-list-item-content>
                  <v-list-item-title class="mb-2">{{ $t('Comment') }}</v-list-item-title>
                  <p class="text-body-2 grey--text text--darken-2 mb-0">{{ event.comment }}</p>
              </v-list-item-content>
          </v-list-item>
      </template>
  </v-list>
</template>

<script>
import { fullDateFormat } from '@/components/calendar/config';
import AdvancePaymentBadge from '@/components/reservations/AdvancePaymentBadge.vue';

export default {
  props: {
    event: {
      required: true,
      type: Object,
    },
    adPreviewUrl: {
      type: String,
    },
    destinationSearchUrl: {
      type: String,
    },
    showOwnerOptions: {
      type: Boolean,
      default: false,
    },
    isAdmin: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    AdvancePaymentBadge,
  },
  computed: {
    paymentLeftover() {
      if (
        this.event
        && this.event.advance_payment
        && this.event.advance_payment_amount
        && this.event.advance_payment_paid
        && this.event.total
        && !this.event.paid
      ) {
        return (this.event.total - this.event.advance_payment_amount).toFixed(2);
      }
      return false;
    },
  },
  methods: {
    fullDateFormat,
  },
};
</script>
