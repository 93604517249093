var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{attrs:{"headers":_vm.headers,"header-props":{sortByText: _vm.$t('Sort by')},"items":_vm.inquries,"items-per-page":500,"hide-default-footer":"","fixed-header":"","disable-pagination":true},scopedSlots:_vm._u([{key:"item.start",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.fullDateFormat(item.start))+" ")]}},{key:"item.end",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.fullDateFormat(item.end))+" ")]}},{key:"item.guest",fn:function(ref){
var item = ref.item;
return [(item.user)?[_vm._v(" "+_vm._s(item.user.first_name)+" "+_vm._s(item.user.last_name)+" ")]:[_vm._v(" "+_vm._s(item.first_name)+" "+_vm._s(item.last_name)+" | "+_vm._s(_vm.$t('Guest'))+" ")],_c('br'),_vm._v(" "+_vm._s(_vm.$tc('guests_count', item.guests))+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":"","disabled":!item.reservation || _vm.$vuetify.breakpoint.smAndDown},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"white--text px-2",staticStyle:{"height":"26px"},attrs:{"color":item.status.color}},'v-chip',attrs,false),on),[(item.reservation && item.reservation.id)?_c('span',{staticClass:"mr-1 cursor-pointer",on:{"click":function($event){$event.stopPropagation();return _vm.loadReservationPreview(item.reservation.id)}}},[_vm._v(_vm._s(item.status[_vm.$i18n.locale]))]):_c('span',{staticClass:"mr-1"},[_vm._v(_vm._s(item.status[_vm.$i18n.locale]))])])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('See reservation details')))])])]}},{key:"item.ad",fn:function(ref){
var item = ref.item;
return [(item && item.ad && item.ad.deleted_at === null)?_c('div',{staticClass:"d-flex align-center"},[_c('a',{attrs:{"href":_vm.getHref(item),"target":"_blank"}},[_vm._v(_vm._s(item.ad.name))])]):(item && item.ad && item.ad.name)?_c('div',[_vm._v(" "+_vm._s(item.ad.name)+" ")]):_c('div',[_vm._v(" "+_vm._s(_vm.$t('The apartment is no longer available for rent'))+" ")])]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.fullDateFormat(item.created_at, true))+" ")]}},{key:"item.total",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-no-wrap"},[_vm._v(" € "+_vm._s(item.total)+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(item && item.ad && item.ad.deleted_at === null)?_c('div',{staticClass:"d-flex align-center"},[_c('v-btn',{staticClass:"text-decoration-underline  text-capitalize cursor-pointer mr-2",on:{"click":function($event){$event.stopPropagation();return _vm.loadInquiryPreview(item.id)}}},[_vm._v(_vm._s(_vm.$t('Details')))]),((_vm.user && _vm.user.id && item && item.ad && item.ad.owner_id === _vm.user.id) || _vm.isAdmin)?_c('v-btn',{staticClass:"mr-2",attrs:{"icon":"","color":"primary","to":{name: 'Calendar', params: {
                categoryConst: 'apartment',
                id: String(item.ad.id),
                focusEventId: item.reservation ? item.reservation.id : null,
                focusEventStart: item.reservation ? item.reservation.start : null,
              }}}},[_c('v-icon',[_vm._v("mdi-calendar")])],1):_vm._e(),(_vm.isAdmin && item.reservation === null)?_c('v-btn',{attrs:{"small":"","outlined":""},on:{"click":function($event){$event.stopPropagation();return _vm.openReservationModal(item)}}},[_vm._v("reserve")]):_vm._e()],1):_vm._e()]}}])}),(_vm.selectedInquiry && _vm.selectedInquiry.ad_id)?_c('reservation-modal',{key:_vm.reservationModalKey,attrs:{"id":_vm.selectedInquiry.ad_id,"makeReservationFromInquiry":_vm.selectedInquiry},on:{"loadReservationInSidebar":function($event){return _vm.$emit('loadReservationInSidebar', $event)},"inquiryToReservationSaved":function($event){return _vm.$emit('inquiryToReservationSaved')}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }