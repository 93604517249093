<template>
    <v-card class="mb-4">
        <v-card-text class="pa-0">
            <div class="d-flex flex-column flex-sm-row align-smß-center justify-sm-space-between pa-4">
              <div class="d-flex align-center">
                  <div>
                  <img
                      v-if="user.media_avatar && Object.values(user.media_avatar)[0] && Object.values(user.media_avatar)[0].original_url"
                      :src="Object.values(user.media_avatar)[0].original_url"
                      alt="User avatar"
                      height="50"
                  />
                  <v-avatar
                      v-else
                      class="ml-2"
                      color="primary white--text pointer"
                      size="28"
                  >
                      {{user.first_name.charAt(0).toUpperCase()}}{{user.last_name.charAt(0).toUpperCase()}}
                  </v-avatar>
                  </div>
                  <div class="ml-5">
                  <h2>{{ user.first_name }} {{ user.last_name }} ({{userType}} {{ user.setting && user.settings.lang ? '| '+ user.settings.lang.toUpperCase() : '' }})</h2>
                  <b>{{ user.phone }}</b>
                  <p>{{ user.email }}</p>
                  </div>
              </div>
              <div>
                <!-- <div>
                  <a
                    :href="viewGuestNovaUrl"
                    target="_blank"
                    class="text-decoration-underline font-weight-medium text-capitalize text-subtitle-1 ml-5"
                    text
                  >
                    View
                  </a>
                  <a
                    :href="editGuestNovaUrl"
                    target="_blank"
                    class="text-decoration-underline font-weight-medium text-capitalize text-subtitle-1 ml-5"
                    text
                  >
                    Edit
                  </a>
                </div> -->
                <v-card-actions>
                  <v-btn
                    text
                    outlined
                    target="_blank"
                    :href="viewGuestNovaUrl"
                    class="mr-2"
                  >
                    View
                  </v-btn>
                  <v-btn
                    text
                    outlined
                    target="_blank"
                    :href="editGuestNovaUrl"
                  >
                    Edit
                  </v-btn>
                  <v-btn
                    v-if="allowSelectUser"
                    @click.stop="$emit('select', user.id)"
                    color="primary"
                    class="ml-4"
                  >
                    Select
                  </v-btn>
                </v-card-actions>
              </div>
            </div>
            <v-expansion-panels
              v-if="user && user.reservations"
              popout
              accordion
              class="pb-4"
              :disabled="!user.reservations.length"
            >
              <v-expansion-panel>
                <v-expansion-panel-header class="py-0">
                <v-card elevation="0">
                  <v-chip
                    color="#DA511A"
                    text-color="white"
                    class="font-weight-medium"
                  >
                    <v-avatar
                      left
                      color="grey lighten-2"
                      class="black--text"
                    >
                      {{ user.reservations.length }}
                    </v-avatar>
                    Reservations
                  </v-chip>
                </v-card>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <reservations-in-table  :reservations="user.reservations"/>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
        </v-card-text>
    </v-card>
</template>

<script>
import ReservationsInTable from '@/components/reservations/ReservationsInTable.vue';
import ApartmentMixin from '@/lib/ApartmentMixin';

import {
  RESERVATION_GUEST_TYPE_USER,
} from '@/components/calendar/config';

export default {
  mixins: [ApartmentMixin],
  props: {
    user: {
      required: true,
      type: Object,
    },
    type: {
      required: true,
      type: String,
    },
    allowSelectUser: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  components: {
    ReservationsInTable,
  },
  computed: {
    userType() {
      return this.type === RESERVATION_GUEST_TYPE_USER ? 'User' : 'Unregistred';
    },
    editGuestNovaUrl() {
      if (this.type === RESERVATION_GUEST_TYPE_USER) {
        return `${this.getNovaUserUrl(this.user.id)}/edit`;
      }
      return `${process.env.VUE_APP_APP_URL}/nova/resources/guestbooks/${this.user.id}/edit`;
    },
    viewGuestNovaUrl() {
      if (this.type === RESERVATION_GUEST_TYPE_USER) {
        return this.getNovaUserUrl(this.user.id);
      }
      return `${process.env.VUE_APP_APP_URL}/nova/resources/guestbooks/${this.user.id}`;
    },
  },
};
</script>

<style scoped lang="scss">

</style>
