<template>
    <modal
        :show="showReservationModal"
        title="Reservation"
        @save="save"
        @close="close"
        @clear="clear"
        :hideActions="isGuestChoosen"
        :fullscreen="true"
        :sticky-header="true"
        :cssClass="`${loading ? 'disable-save' : ''}`"
    >
        <v-container fluid>
          <errors-backend/>
          <v-row>
              <v-col cols="12" lg="7" xl="8">
                <reservation-form
                  v-if="isGuestChoosen"
                  :id="id"
                  @update="updatePaymentForm"
                  ref="reservationForm"
                />
                <v-col cols="12" md="6" xl="4" class="pb-0 mb-4 pl-0 pr-0 pr-md-2">
                  <v-card outlined class="px-3 py-2 d-flex align-center">
                    <span class="font-weight-medium text-body-1 mr-auto">Language</span>
                    <v-radio-group
                      row
                      v-model="form.lang"
                      class="mt-0 pl-5 text-capitalize hide-error-messages"
                    >
                      <v-radio :value="'sr'" label="Serbian"/>
                      <v-radio :value="'en'" label="English"/>
                    </v-radio-group>
                  </v-card>
                </v-col>
                <guest-picker
                  @selectUser="selectUser"
                  @selectGuestbook="selectGuestbook"
                  @newGuest="newGuest"
                  @reset="resetGuest"
                  :key="guestPickerKey"
                  :makeReservationFromInquiry="makeReservationFromInquiry"
                />
                <v-text-field
                  v-model="form.gacid"
                  type="text"
                  class="mt-2"
                  outlined
                  label="GACID"
                  hint="Copy from Inquiry or Email"
                  persistent-hint
                />
              </v-col>
              <v-col
                cols="12"
                lg="5"
                xl="4"
                style="border-left:1px solid #ccc;"
                class="px-0 mt-8 mt-lg-0"
              >
                  <pricing-preview
                      v-if="calculator && calculator.start && calculator.end"
                      :key="`${calculator.start}${calculator.end}`"
                      :customPrice="form.custom_pricing ? form.custom_price : undefined"
                      :show="true"
                      :id="id"
                      :start="start"
                      :end="end"
                      :calculator-data="calculator"
                      :showInModal="false"
                  />
              </v-col>
          </v-row>
        </v-container>
    </modal>
</template>

<script>
import Modal from '@/components/Modal.vue';
import axios from 'axios';
import PricingPreview from '@/components/PricingPreview.vue';
import GuestPicker from '@/components/reservations/GuestPicker.vue';
import ReservationForm from '@/components/reservations/ReservationForm.vue';
import { mapGetters } from 'vuex';
import {
  RESERVATION_GUEST_TYPE_USER,
  RESERVATION_GUEST_TYPE_GUESTBOOK,
} from '@/components/calendar/config';

export default {
  props: {
    id: {
      required: true,
      type: String,
    },
    start: {
      required: false,
    },
    end: {
      required: false,
    },
    makeReservationFromInquiry: {
      type: Object,
      required: false,
    },

  },
  components: {
    Modal,
    PricingPreview,
    GuestPicker,
    ReservationForm,
  },

  data() {
    return {
      form: {
        guest_id: undefined,
        guest_type: undefined,
        new_guest: undefined,
        makeReservationFromInquiryId: undefined,

        gacid: undefined,

        // // Reservation Form
        paid: false,
        advance_payment: false,
        advance_payment_paid: false,

        advance_payment_amount: undefined,
        advance_payment_due_date: undefined,
        advance_payment_due_time: undefined,
        advance_payment_payout_method_id: undefined,

        advance_payment_percentage: undefined,

        custom_pricing: false,
        custom_price: {
          total: undefined,
        },
        comment: undefined,

        lang: 'sr',
      },
      guestPickerKey: 1,
    };
  },
  computed: {
    ...mapGetters(['calculator', 'showReservationModal', 'loading']),
    isGuestChoosen() {
      if ((this.form.guest_id && this.form.guest_type) || (this.form.new_guest && this.form.new_guest.first_name && this.form.new_guest.last_name && this.form.new_guest.phone)) {
        return true;
      }
      return false;
    },
  },
  mounted() {
    if (this.makeReservationFromInquiry) {
      if (this.makeReservationFromInquiry.gacid) {
        this.form.gacid = this.makeReservationFromInquiry.gacid;
      }
      if (this.makeReservationFromInquiry.lang) {
        this.form.lang = this.makeReservationFromInquiry.lang;
      }
    }
  },
  methods: {
    save() {
      if (this.loading) {
        return;
      }
      // this.$refs.reservationForm.$v.touch();
      this.$refs.reservationForm.validateForm();
      // this.$v.$touch();
      if (this.$refs.reservationForm.$v.$invalid) {
        const errorMessages = this.$refs.reservationForm.collectErrorMessages();
        // Combine the messages into a single string with line breaks between each message.
        // console.log('errorMessages', errorMessages);
        const alertMessage = errorMessages.join('\n');
        alert(alertMessage);
        // alert('Fill all information');
        return;
      }
      this.form.start = this.start;
      this.form.end = this.end;
      this.form.calculator = this.calculator;
      if (this.makeReservationFromInquiry && this.makeReservationFromInquiry.id) {
        this.form.makeReservationFromInquiryId = this.makeReservationFromInquiry.id;
      }
      this.$store.dispatch('loading', true);
      axios
        .post(`calendar/reservations/${this.id}`, {
          ...this.form,
        })
        .then(({ data }) => {
          this.$store.dispatch('message', 'Reservation Saved');
          if (data.reservation_id) {
            this.$emit('loadReservationInSidebar', data.reservation_id);
          }
          if (this.makeReservationFromInquiry && this.makeReservationFromInquiry.id) {
            this.$emit('inquiryToReservationSaved');
          }
          this.close();
          this.$store.dispatch('loading', false);
          this.$store.dispatch('setErrors', []);
        })
        .catch((error) => {
          this.$store.dispatch('setErrors', error.response.data.errors);
          this.$store.dispatch('loading', false);
          this.isSaveButtonDisabled = false;
        });
    },
    close() {
      this.clear();
      // this.$emit('close');
      this.$store.commit('toggleReservationModal');
      this.$store.dispatch('setErrors', []);
    },
    clear() {
      this.form = {
        guest_id: undefined,
        guest_type: undefined,
        new_guest: undefined,

        lang: 'sr',
      };
      this.guestPickerKey += 1;
    },
    selectGuestbook(id) {
      this.form.guest_id = id;
      this.form.guest_type = RESERVATION_GUEST_TYPE_GUESTBOOK;
    },
    selectUser(id) {
      // call ajax to get preffered locale for user.
      if (this.form.guest_id !== id && (!this.makeReservationFromInquiry || !this.makeReservationFromInquiry.lang)) {
        axios
          .get(`users/${id}/preferred-locale`)
          .then(({ data }) => {
            this.form.lang = data;
          })
          .catch(() => {
            console.warn('fail to fetch user preferred locale');
          });
      }
      this.form.guest_id = id;
      this.form.guest_type = RESERVATION_GUEST_TYPE_USER;
    },
    resetGuest() {
      this.form.guest_id = undefined;
      this.form.guest_type = undefined;
      this.form.new_guest = undefined;
    },
    newGuest(guest) {
      this.form.new_guest = guest;
    },
    updatePaymentForm(form) {
      // this.form.payment = form;
      this.form = { ...this.form, ...form };
    },
  },
};
</script>

<style scoped lang="scss">

</style>
